import { useState } from "react";
import FiltersContext from "./FiltersContext";

function FiltersProvider({ children }) {
    const [showFilterGroupAddDialog, setShowFilterGroupAddDialog] = useState(false);

    let values = { showFilterGroupAddDialog, setShowFilterGroupAddDialog };

    return <FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>;
}

export default FiltersProvider;
