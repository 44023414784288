import { useState } from "react";
import FiltersContext from "./OptionsContext";

function FiltersProvider({ children }) {
    const [showOptionAddDialog, setShowOptionAddDialog] = useState(false);

    const values = { showOptionAddDialog, setShowOptionAddDialog };

    return <FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>;
}

export default FiltersProvider;
