import { lazy, Suspense } from "react";
import SuspenseFallback from "parts/SuspenseFallback";
import ExtraWorkingHoursProvider from "store/extraWorkingHours/ExtraWorkingHoursProvider";
import VacationLeaveProvider from "store/vacationLeave/VacationLeaveProvider";
import VariantsProvider from "store/variants/VariantsProvider";
import EmployeesProvider from "store/employees/EmployeesProvider";
import TreatmentsProvider from "store/treatments/TreatmentsProvider";
import CategoriesProvider from "store/categories/CategoriesProvider";
import OptionsProvider from "store/options/OptionsProvider";
import FiltersProvider from "store/filters/FiltersProvider";
import AdditionalOpeningHoursProvider from "store/additionalOpeningHours/AdditionalOpeningHoursProvider";
import Forbidden from "parts/Forbidden";
import NotFound from "parts/NotFound";
import UserAccess from "components/UserAccess";

const AdditionalOpeningHours = lazy(() => import("../../pages/Outlets/AdditionalOpeningHours"));
const BackOffice = lazy(() => import("../../pages/BackOffice"));
const Outlets = lazy(() => import("../../pages/Outlets"));
const Outlet = lazy(() => import("../../pages/Outlets/Outlet"));
const AddOutlet = lazy(() => import("../../pages/Outlets/AddOutlet"));
const EditOutlet = lazy(() => import("../../pages/Outlets/EditOutlet"));
const Employees = lazy(() => import("../../pages/Employees"));
const Employee = lazy(() => import("../../pages/Employees/Employee"));
const AddEmployee = lazy(() => import("../../pages/Employees/AddEmployee"));
const EditEmployee = lazy(() => import("../../pages/Employees/EditEmployee"));
// const EmployeeOutlets = lazy(() => import("../../pages/Employees/EmployeeOutlets"));
const WorkingHours = lazy(() => import("../../pages/Employees/WorkingHours"));
const ExtraWorkingHours = lazy(() => import("../../pages/Employees/ExtraWorkingHours"));
const VacationLeave = lazy(() => import("../../pages/Employees/VacationLeave"));
const Sessions = lazy(() => import("../../pages/Sessions"));
const Treatments = lazy(() => import("../../pages/Treatments"));
const TreatmentDashboard = lazy(() => import("../../pages/Treatments/Treatment/TreatmentDashboard"));
const AddTreatment = lazy(() => import("../../pages/Treatments/AddTreatment"));
const EditTreatment = lazy(() => import("../../pages/Treatments/Treatment/EditTreatment"));
const TreatmentVariants = lazy(() => import("../../pages/Treatments/Treatment/TreatmentVariants"));
const TreatmentOutlets = lazy(() => import("../../pages/Treatments/Treatment/TreatmentOutlets"));
const TreatmentFilters = lazy(() => import("../../pages/Treatments/Treatment/TreatmentFilters"));
const TreatmentFaq = lazy(() => import("../../pages/Treatments/Treatment/TreatmentFaq"));
const TreatmentRelations = lazy(() => import("../../pages/Treatments/Treatment/TreatmentRelations"));
const TreatmentOptions = lazy(() => import("../../pages/Treatments/Treatment/TreatmentOptions"));
const TreatmentSteps = lazy(() => import("../../pages/Treatments/Treatment/TreatmentSteps"));
const Categories = lazy(() => import("../../pages/Treatments/Categories"));
const AddCategory = lazy(() => import("../../pages/Treatments/Categories/AddCategory"));
const EditCategory = lazy(() => import("../../pages/Treatments/Categories/EditCategory"));
const Options = lazy(() => import("../../pages/Treatments/Options"));
const AddOption = lazy(() => import("../../pages/Treatments/Options/AddOption"));
const EditOption = lazy(() => import("../../pages/Treatments/Options/EditOption"));
const Filters = lazy(() => import("../../pages/Treatments/Filters"));
const FilterValues = lazy(() => import("../../pages/Treatments/Filters/FilterValues"));
const Settings = lazy(() => import("../../pages/Settings"));
const MessageTemplates = lazy(() => import("../../pages/MessageTemplates"));
const EditMessageTemplate = lazy(() => import("../../pages/MessageTemplates/EditMessageTemplate"));

const MessagesLayout = lazy(() => import("../../layout/MessagesLayout"));
const SettingsLayout = lazy(() => import("../../layout/SettingsLayout"));
const EmployeesLayout = lazy(() => import("../../layout/EmployeesLayout"));
const OutletsLayout = lazy(() => import("../../layout/OutletsLayout"));
const TreatmentsLayout = lazy(() => import("../../layout/TreatmentsLayout"));
const TreatmentLayout = lazy(() => import("../../layout/TreatmentLayout"));
const FilterLayout = lazy(() => import("../../layout/FilterLayout"));

export const backOfficeRoutes = [
    {
        index: true,
        handle: {
            id: "backoffice"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <BackOffice />
            </Suspense>
        )
    },
    {
        path: "outlets",
        handle: {
            id: "outlets"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <UserAccess roles={["admin", "manager"]} renderDenied={<Forbidden />}>
                    <OutletsLayout />
                </UserAccess>
            </Suspense>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "outlets"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <Outlets archived={false} />
                    </Suspense>
                )
            },
            {
                path: "archived",
                handle: {
                    id: "outletsArchived"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <Outlets archived={true} />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "outlets/add",
        handle: {
            id: "outlet_add"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <AddOutlet />
            </Suspense>
        )
    },
    {
        path: "outlets/:uid",
        handle: {
            id: "outlet"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "outlet_edit"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <EditOutlet />
                    </Suspense>
                )
            },
            {
                path: "edit",
                handle: {
                    id: "outlet_edit"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <EditOutlet />
                    </Suspense>
                )
            },
            {
                path: "additional-opening-hours",
                handle: {
                    id: "additional_opening_hours"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <AdditionalOpeningHoursProvider>
                            <AdditionalOpeningHours />
                        </AdditionalOpeningHoursProvider>
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "employees",
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <EmployeesProvider>
                    <EmployeesLayout />
                </EmployeesProvider>
            </Suspense>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "employees"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <Employees archived={false} />
                    </Suspense>
                )
            },
            {
                path: "archived",
                handle: {
                    id: "employeesArchived"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <Employees archived={true} />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "employees/add",
        handle: {
            id: "employee_add"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <AddEmployee />
            </Suspense>
        )
    },
    {
        path: "employees/:uid",
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <Employee />
            </Suspense>
        ),
        children: [
            {
                path: "edit",
                handle: {
                    id: "employee_edit"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <EditEmployee />
                    </Suspense>
                )
            },
            {
                path: "working-hours",
                handle: {
                    id: "employee_edit_working_hours"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <WorkingHours />
                    </Suspense>
                )
            },
            {
                path: "extra-working-hours",
                handle: {
                    id: "employee_extra_working_hours"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <ExtraWorkingHoursProvider>
                            <ExtraWorkingHours />
                        </ExtraWorkingHoursProvider>
                    </Suspense>
                )
            },
            {
                path: "vacation-leave",
                handle: {
                    id: "employee_leave"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <VacationLeaveProvider>
                            <VacationLeave />
                        </VacationLeaveProvider>
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "sessions",
        handle: {
            id: "sessions"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <Sessions />
            </Suspense>
        )
    },
    {
        path: "treatments",
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <TreatmentsProvider>
                    <TreatmentsLayout />
                </TreatmentsProvider>
            </Suspense>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "treatments"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <Treatments />
                    </Suspense>
                )
            },
            {
                path: "categories",
                handle: {
                    id: "categories"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <CategoriesProvider>
                            <Categories />
                        </CategoriesProvider>
                    </Suspense>
                )
            },
            {
                path: "options",
                handle: {
                    id: "options"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <OptionsProvider>
                            <Options />
                        </OptionsProvider>
                    </Suspense>
                )
            },
            {
                path: "filters",
                handle: {
                    id: "filters"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <FiltersProvider>
                            <Filters />
                        </FiltersProvider>
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "treatments/filters/:uid",
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <FilterLayout />
            </Suspense>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "filter"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <NotFound />
                    </Suspense>
                )
            },
            {
                path: "values",
                handle: {
                    id: "filter_values"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <FilterValues />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "treatments/categories/add",
        handle: {
            id: "category_add"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <AddCategory />
            </Suspense>
        )
    },
    {
        path: "treatments/categories/:uid/edit",
        handle: {
            id: "category_edit"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <EditCategory />
            </Suspense>
        )
    },
    {
        path: "treatments/options/add",
        handle: {
            id: "option_add"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <AddOption />
            </Suspense>
        )
    },
    {
        path: "treatments/options/:uid/edit",
        handle: {
            id: "option_edit"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <EditOption />
            </Suspense>
        )
    },
    {
        path: "treatments/add",
        handle: {
            id: "treatment_add"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <AddTreatment />
            </Suspense>
        )
    },
    {
        path: "treatments/:uid",
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <TreatmentLayout />
            </Suspense>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "treatment_dashboard"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentDashboard />
                    </Suspense>
                )
            },
            {
                path: "edit",
                handle: {
                    id: "treatment_edit"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <EditTreatment />
                    </Suspense>
                )
            },
            {
                path: "variants",
                handle: {
                    id: "treatment_variants"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <VariantsProvider>
                            <TreatmentVariants />
                        </VariantsProvider>
                    </Suspense>
                )
            },
            {
                path: "outlets",
                handle: {
                    id: "treatment_outlets"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentOutlets />
                    </Suspense>
                )
            },
            {
                path: "filters",
                handle: {
                    id: "treatment_filters"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentFilters />
                    </Suspense>
                )
            },
            {
                path: "relations",
                handle: {
                    id: "treatment_relations"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentRelations />
                    </Suspense>
                )
            },
            {
                path: "options",
                handle: {
                    id: "treatment_options"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentOptions />
                    </Suspense>
                )
            },
            {
                path: "veelgestelde-vragen",
                handle: {
                    id: "treatment_faqs"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentFaq />
                    </Suspense>
                )
            },
            {
                path: "steps",
                handle: {
                    id: "treatment_steps"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <TreatmentSteps />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "messages",
        element: (
            <UserAccess roles={["admin", "manager"]} renderDenied={<Forbidden />}>
                <Suspense fallback={<SuspenseFallback />}>
                    <MessagesLayout />
                </Suspense>
            </UserAccess>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "messageTemplates"
                },
                element: (
                    <UserAccess roles={["admin", "manager"]} renderDenied={<Forbidden />}>
                        <Suspense fallback={<SuspenseFallback />}>
                            <MessageTemplates />
                        </Suspense>
                    </UserAccess>
                )
            },
            {
                path: ":uid/edit",
                handle: {
                    id: "messageTemplate_edit"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <EditMessageTemplate />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "settings",
        element: (
            <UserAccess roles={["admin", "manager"]} renderDenied={<Forbidden />}>
                <Suspense fallback={<SuspenseFallback />}>
                    <SettingsLayout />
                </Suspense>
            </UserAccess>
        ),
        children: [
            {
                index: true,
                handle: {
                    id: "settings"
                },
                element: (
                    <Suspense fallback={<SuspenseFallback />}>
                        <UserAccess roles={["admin"]} renderDenied={<Forbidden />}>
                            <Settings />
                        </UserAccess>
                    </Suspense>
                )
            }
        ]
    }
];

export default backOfficeRoutes;
