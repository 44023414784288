import { RecoilRoot } from "recoil";
import Routes from "routes";
import CatchError from "parts/CatchError";
import Authentication from "parts/Authentication";
import "react-toastify/dist/ReactToastify.min.css";
import "./styles/styles.scss";

function App() {
    return (
        <CatchError>
            <RecoilRoot>
                <Authentication>
                    <Routes />
                </Authentication>
            </RecoilRoot>
        </CatchError>
    );
}

export default App;
