export const navigator = new Map([
    [
        "backoffice",
        {
            url: "/backoffice",
            menuText: "Backoffice",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "additional_opening_hours",
        {
            url: (uid) => `/backoffice/outlets/${uid}/additional-opening-hours`,
            menuText: "Aangepaste openingstijden",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "appointment_add",
        {
            url: "/appointment/add",
            menuText: "Nieuwe afspraak",
            contextMenuText: "Afspraak toevoegen"
        }
    ],
    [
        "appointment_edit",
        {
            url: (uid) => `/appointment/${uid}/edit`,
            menuText: "Afspraak bewerken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "book",
        {
            url: "/appointment/add",
            menuText: "Inboeken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "branches",
        {
            url: "/backoffice/branches",
            menuText: "Vestigingen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "break",
        {
            url: "/break/add",
            menuText: "Pauze",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "cancellations",
        {
            url: "/appointments/cancellations",
            menuText: "Annuleringen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "calendar",
        {
            url: "/calendar",
            menuText: "Agenda",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "customer_add",
        {
            url: "/customers/add",
            menuText: "Nieuwe klant",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "customers",
        {
            url: "/customers",
            menuText: "Overzicht",
            contextMenuText: "Klanten"
        }
    ],
    [
        "customer_edit",
        {
            url: (uid) => `/customers/${uid}/edit`,
            menuText: "Klantprofiel bewerken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "customers_search",
        {
            url: (query) => `/customers?q=${query}`,
            menuText: "Klanten zoeken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "customer_dashboard",
        {
            url: (uid) => `/customers/${uid}`,
            menuText: "Overzicht",
            contextMenuText: "Klant bekijken"
        }
    ],
    [
        "customer_appointments",
        {
            url: (uid) => `/customers/${uid}/appointments`,
            menuText: "Afspraken",
            contextMenuText: "Afspraken bekijken"
        }
    ],
    [
        "customer_appointment",
        {
            url: (customer_uid, uid) => `/customers/${customer_uid}/appointments/${uid}`,
            menuText: "Afspraak",
            contextMenuText: "Bewerken"
        }
    ],
    [
        "customer_analysis",
        {
            url: (uid) => `/customers/${uid}/analysis`,
            menuText: "Analyses",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "customer_sales",
        {
            url: (uid) => `/customers/${uid}/sales`,
            menuText: "Verkoop",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "dashboard",
        {
            url: "/",
            menuText: "Dashboard",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "employee_dashboard",
        {
            url: (uid) => `/backoffice/employees/${uid}/edit`,
            menuText: "Profiel",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "employee_add",
        {
            url: "/backoffice/employees/add",
            menuText: "Medewerker toevoegen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "employee_edit",
        {
            url: (uid) => `/backoffice/employees/${uid}/edit`,
            menuText: "Bewerken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "employee_edit_working_hours",
        {
            url: (uid) => `/backoffice/employees/${uid}/working-hours`,
            menuText: "Werktijden",
            contextMenuText: "Werktijden bekijken"
        }
    ],
    [
        "employee_extra_working_hours",
        {
            url: (uid) => `/backoffice/employees/${uid}/extra-working-hours`,
            menuText: "Extra werktijden",
            contextMenuText: "Extra werktijden bekijken"
        }
    ],
    [
        "employee_leave",
        {
            url: (uid) => `/backoffice/employees/${uid}/vacation-leave`,
            menuText: "Vakantie / verlof",
            contextMenuText: "Vakantie / verlof bekijken"
        }
    ],
    [
        "employees",
        {
            url: "/backoffice/employees",
            menuText: "Medewerkers",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "employeesActive",
        {
            url: "/backoffice/employees",
            menuText: "Overzicht",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "employeesArchived",
        {
            url: "/backoffice/employees/archived",
            menuText: "Gearchiveerd",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "messageTemplates",
        {
            url: "/backoffice/messages",
            menuText: "Uitgaande berichten",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "messageTemplate_edit",
        {
            url: (uid) => `/backoffice/messages/${uid}/edit`,
            menuText: "Bewerken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "my_profile",
        {
            url: "/my-profile",
            menuText: "Mijn profiel",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outlet",
        {
            url: (uid) => `/backoffice/outlets/${uid}`,
            menuText: "Vestiging",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outlet_add",
        {
            url: "/backoffice/outlets/add",
            menuText: "Nieuwe vestiging",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outlet_dashboard",
        {
            url: (uid) => `/backoffice/outlets/${uid}/edit`,
            menuText: "Algemeen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outlet_edit",
        {
            url: (uid) => `/backoffice/outlets/${uid}/edit`,
            menuText: "Vestiging bewerken",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outlets",
        {
            url: "/backoffice/outlets",
            menuText: "Vestigingen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outletsActive",
        {
            url: "/backoffice/outlets",
            menuText: "Overzicht",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "outletsArchived",
        {
            url: "/backoffice/outlets/archived",
            menuText: "Gearchiveerd",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "recent",
        {
            url: "/appointments",
            menuText: "Recent",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "waitingList",
        {
            url: "/appointments/waiting-list",
            menuText: "Wachtlijst",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "reservations",
        {
            url: "/appointments/reservations",
            menuText: "Reserveringen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],

    [
        "sessions",
        {
            url: "/backoffice/sessions",
            menuText: "Sessies",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "settings",
        {
            url: "/backoffice/settings",
            menuText: "Instellingen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "treatments",
        {
            url: "/backoffice/treatments",
            menuText: "Behandelingen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "treatment_dashboard",
        {
            url: (uid) => `/backoffice/treatments/${uid}`,
            menuText: "Overzicht",
            contextMenuText: "Naar behandeling"
        }
    ],
    [
        "treatment_add",
        {
            url: "/backoffice/treatments/add",
            menuText: "Behandeling toevoegen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "treatment_edit",
        {
            url: (uid) => `/backoffice/treatments/${uid}/edit`,
            menuText: "Behandeling bewerken",
            contextMenuText: "Bewerken"
        }
    ],
    [
        "treatment_variants",
        {
            url: (uid) => `/backoffice/treatments/${uid}/variants`,
            menuText: "Varianten",
            contextMenuText: "Varianten bekijken"
        }
    ],
    [
        "treatment_employees",
        {
            url: (uid) => `/backoffice/treatments/${uid}/employees`,
            menuText: "Medewerkers",
            contextMenuText: "Gekoppelde medewerkers"
        }
    ],
    [
        "treatment_outlets",
        {
            url: (uid) => `/backoffice/treatments/${uid}/outlets`,
            menuText: "Vestigingen",
            contextMenuText: "Gekoppelde vestigingen"
        }
    ],
    [
        "treatment_filters",
        {
            url: (uid) => `/backoffice/treatments/${uid}/filters`,
            menuText: "Filters",
            contextMenuText: "Filters bekijken"
        }
    ],
    [
        "treatment_faq",
        {
            url: (uid) => `/backoffice/treatments/${uid}/veelgestelde-vragen`,
            menuText: "Veelgestelde vragen",
            contextMenuText: "Veelgestelde vragen bekijken"
        }
    ],
    [
        "treatment_steps",
        {
            url: (uid) => `/backoffice/treatments/${uid}/steps`,
            menuText: "Stappen",
            contextMenuText: "Stappen bekijken"
        }
    ],
    [
        "treatment_relations",
        {
            url: (uid) => `/backoffice/treatments/${uid}/relations`,
            menuText: "Relaties",
            contextMenuText: "Relaties bekijken"
        }
    ],
    [
        "treatment_options",
        {
            url: (uid) => `/backoffice/treatments/${uid}/options`,
            menuText: "Extra opties",
            contextMenuText: "Extra opties bekijken"
        }
    ],
    [
        "categories",
        {
            url: "/backoffice/treatments/categories",
            menuText: "Categorieën",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "category_add",
        {
            url: "/backoffice/treatments/categories/add",
            menuText: "Categorie toevoegen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "category_edit",
        {
            url: (uid) => `/backoffice/treatments/categories/${uid}/edit`,
            menuText: "Categorie bewerken",
            contextMenuText: "Bewerken"
        }
    ],
    [
        "options",
        {
            url: "/backoffice/treatments/options",
            menuText: "Extra opties",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "option_add",
        {
            url: "/backoffice/treatments/options/add",
            menuText: "Optie toevoegen",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "option_edit",
        {
            url: (uid) => `/backoffice/treatments/options/${uid}/edit`,
            menuText: "Optie bewerken",
            contextMenuText: "Bewerken"
        }
    ],
    [
        "filters",
        {
            url: "/backoffice/treatments/filters",
            menuText: "Filters",
            get contextMenuText() {
                return this.menuText;
            }
        }
    ],
    [
        "filter",
        {
            url: (uid) => `/backoffice/treatments/filters/${uid}`,
            menuText: "Filtergroep",
            contextMenuText: "Filtergroep bekijken"
        }
    ],
    [
        "filter_values",
        {
            url: (uid) => `/backoffice/treatments/filters/${uid}/values`,
            menuText: "Filterwaarden",
            contextMenuText: "Filterwaarden toevoegen"
        }
    ]
]);
