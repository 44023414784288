import { ErrorBoundary } from "react-error-boundary";
import { useState } from "react";
import NotFoundPlaceholder from "components/NotFoundPlaceholder";
import Button from "components/Button";
import Pre from "components/Pre";
import Text from "components/Text";
import config from "constants/config";
import { ReactComponent as RefreshIcon } from "assets/icons/light/arrow-rotate-right.svg";

export function CatchError({ children }) {
    const [boundaryError, setBoundaryError] = useState(null);
    const [stackTrace, setStackTrace] = useState(null);

    return (
        <ErrorBoundary
            onError={(error, info) => {
                setBoundaryError(error);
                setStackTrace(info?.componentStack);
            }}
            fallback={
                <>
                    <NotFoundPlaceholder size="large" image="questions" title="Oops...">
                        <Text marginBottom={3}>Er is iets fout gegaan! Probeer de pagina te herladen.</Text>

                        <Button color="secondary" icon={<RefreshIcon />} onClick={() => window.location.reload()}>
                            Herladen
                        </Button>
                    </NotFoundPlaceholder>

                    {config?.environment !== "production" && (
                        <>
                            {boundaryError?.message && (
                                <Pre marginBottom={3} marginLeft={3} marginRight={3}>
                                    {boundaryError.message}
                                </Pre>
                            )}
                            {stackTrace && (
                                <Pre marginBottom={3} marginLeft={3} marginRight={3}>
                                    {stackTrace}
                                </Pre>
                            )}
                        </>
                    )}
                </>
            }
        >
            {children}
        </ErrorBoundary>
    );
}
