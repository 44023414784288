import { snakeToPascal } from "helpers/caseConverter";
import { ReactComponent as Articles } from "assets/illustrations/articles.svg";
import { ReactComponent as Checklist } from "assets/illustrations/checklist.svg";
import { ReactComponent as CoWorkers } from "assets/illustrations/co_workers.svg";
import { ReactComponent as EmptyCart } from "assets/illustrations/empty_cart.svg";
import { ReactComponent as ExcitingNews } from "assets/illustrations/exciting_news.svg";
import { ReactComponent as FileManager } from "assets/illustrations/file_manager.svg";
import { ReactComponent as IdeasFlow } from "assets/illustrations/ideas_flow.svg";
import { ReactComponent as OnlineCalendar } from "assets/illustrations/online_calendar.svg";
import { ReactComponent as KeyPoints } from "assets/illustrations/key_points.svg";
import { ReactComponent as Meditation } from "assets/illustrations/meditation.svg";
import { ReactComponent as MessagingFun } from "assets/illustrations/messaging_fun.svg";
import { ReactComponent as PeopleSearch } from "assets/illustrations/people_search.svg";
import { ReactComponent as ProjectCompleted } from "assets/illustrations/project_completed.svg";
import { ReactComponent as Questions } from "assets/illustrations/questions.svg";
import { ReactComponent as Respond } from "assets/illustrations/respond.svg";
import { ReactComponent as Schedule } from "assets/illustrations/schedule.svg";
import { ReactComponent as TimeManagement } from "assets/illustrations/time_management.svg";
import { ReactComponent as ToDoList } from "assets/illustrations/to_do_list.svg";
import { ReactComponent as TravelMode } from "assets/illustrations/travel_mode.svg";
import { ReactComponent as UrbanDesign } from "assets/illustrations/urban_design.svg";
import { ReactComponent as Warning } from "assets/illustrations/warning.svg";
import { ReactComponent as WorkInProgress } from "assets/illustrations/work_in_progress.svg";

export function SVG({ name, type = "icons" }) {
    if (type === "icons") {
    } else if (type === "illustrations") {
        const Illustrations = {
            Articles,
            Checklist,
            CoWorkers,
            EmptyCart,
            FileManager,
            IdeasFlow,
            OnlineCalendar,
            KeyPoints,
            ExcitingNews,
            Meditation,
            MessagingFun,
            PeopleSearch,
            ProjectCompleted,
            Questions,
            Respond,
            Schedule,
            TimeManagement,
            ToDoList,
            TravelMode,
            UrbanDesign,
            Warning,
            WorkInProgress
        };

        const Illustration = Illustrations[snakeToPascal(name)];

        return <Illustration />;
    }

    return <></>;
}
